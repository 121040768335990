import { CloseOutlined, EyeInvisibleOutlined, GlobalOutlined, MenuOutlined, PicLeftOutlined, TeamOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Form, Input, Layout, Radio, Select, Space, Spin, Table, Tooltip } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import BrowseFilters from "../components/BrowseFilters";
import ListArchive from "../components/lists/ListArchive";
import ListCreateEdit from "../components/lists/ListCreateEdit";
import ListFav from "../components/lists/ListFav";
import ListFolder from "../components/lists/ListFolder";
import ListItem from "../components/lists/ListItem";
import ListMultiSelect from '../components/lists/ListMultiSelect';
import ListQuickPick from "../components/lists/ListQuickPick";
import ListTrash from "../components/lists/ListTrash";
import Overlay from "../components/Overlay";
import Paginate from "../components/Paginate";
import Renderer from "../components/Renderer";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import { useSession } from '../utils/Session';
import { disabledColor, objectToPath, pathToObject } from "../utils/Utils";

export default function MyListsPage(props) {

    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({ rows: [] });
    const [catalogueResults, setCatalogueResults] = useState({ rows: [] })
    const [expanded, setExpanded] = useState([]);
    const [showDetails, setShowDetails] = useState((Cookies.get("lists_show_list") === "true") ? false : true);
    const path = props.is_company ? "/catalogues/my-company-catalogues" : "/catalogues/my-catalogues";
    const api_string = props.is_company ? "titlelist/getCorpCatalogues" : "titlelist/getMyCatalogues"
    const [session, setSession] = useSession();
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname, "", props.is_company, session.corp_id))
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [filters, setFilters] = useState({});
    const [initLoaded, setInitLoaded] = useState(false);
    const [listLabel, setListLabel] = useState({ key: "", value: "" })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectMultiple, setSelectMultiple] = useState(true);
    const [presetSANLists, setPresetSANLists] = useState([]);

    function getBeginningValues() {
        apiCall("contact_list/getLists", {}, (_status, _result) => {
            if (_status) {
                setPresetSANLists(_result);
            }
        })
    }

    useEffect(getBeginningValues, [])

    const getCatalogues = (_change = false, _obj = searchFilters) => {
        setLoading(true);
        let _filters = { ..._obj };
        _filters.filters = JSON.stringify(_filters.filters);
        apiCall(api_string, _filters, (_status, _result) => {
            if (_status) {
                if (_result.row_count === 1) {
                    if (_obj.hasOwnProperty("t")) {
                        if (!isNaN(_obj.t)) {
                            setListLabel({ key: _result.rows[0].actual_id, value: _result.rows[0].name })
                        }
                    }
                }
                setFilters(_result.filter_info);
                setResults(_result);
                setLoading(false);
                setInitLoaded(true);
            }
        })
    }

    const headerSearch = () => {
        window.scrollTo(0, 0);
        setLoading(true);
        let obj = pathToObject(location.pathname);
        setSearchFilters(obj);
        getCatalogues(false, obj);
    }

    useEffect(headerSearch, [location.pathname])

    const expandCell = (_data) => {
        if (!expanded.includes(_data.list_id)) {
            setExpanded([_data.list_id]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "marginTop": "-4px", "marginLeft": "-8px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <Renderer key={_record.list_id}>
                            <ListItem
                                getCatalogues={getCatalogues}
                                setResults={setResults}
                                results={results}
                                data={_record}
                                selectedRowKeys={selectedRowKeys}
                                presetSANLists={presetSANLists}
                                toggleMultiSelect={toggleMultiSelect}
                            />
                        </Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    function toggleMultiSelect(ms_list_id, checked) {
        let tempSelectedKeys = [...selectedRowKeys]
        if (checked) {
            tempSelectedKeys.push(ms_list_id);
        } else {
            tempSelectedKeys.splice(tempSelectedKeys.indexOf(ms_list_id), 1);
        }
        setSelectedRowKeys(tempSelectedKeys);
    }

    function checkIfSelected(row_data) {
        let is_selected = false;
        if (selectedRowKeys.some((row) => row === row_data.list_id)) {
            is_selected = true;
        }
        return is_selected;
    }

    function sharedIcon(shared_status) {
        if (shared_status === "none") {
            return (
                <Tooltip title="Not shared with anyone">
                    <EyeInvisibleOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#FF0000" }} />
                </Tooltip>
            )
        } else if (shared_status === "some_my") {
            return (
                <Tooltip title="Shared with some of your accounts">
                    <UserAddOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
                </Tooltip>
            )
        } else if (shared_status === "some") {
            return (
                <Tooltip title="Shared with some stores">
                    <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
                </Tooltip>
            )
        } else if (shared_status === "one_my") {
            return (
                <Tooltip title="Shared with one of your accounts">
                    <UserOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#D16004" }} />
                </Tooltip>
            )
        } else if (shared_status === "all") {
            return (
                <Tooltip title="Shared with all of your accounts">
                    <TeamOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
                </Tooltip>
            )
        } else if (shared_status === "everyone") {
            return (
                <Tooltip title="Shared publicly with everyone">
                    <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
                </Tooltip>
            )
        }
    }

    const columns = [
        { key: "blank", width: "10px", render : () => {
            return(<div style={{"height" : "33px"}}>&nbsp;</div>)
        } },
        {
            title: <small>List name</small>, width: '250px', dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => expandCell(f)}>{e}</a> : "";
            }
        },
        {
            title: <small>Shared</small>, width: '55px', dataIndex: 'shared', key: 'shared', render: (e, f) => {
                return sharedIcon(f.shared_status)
            }
        },
        {
            title: <small>Titles</small>, width: '55px', dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: <small>Last Updated</small>, dataIndex: 'date_updated', key: 'date_updated', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div><div style={{ "color": disabledColor }}>
                        </div>
                    </>
                )
            }
        },
        {
            title: <small>Category</small>, dataIndex: 'category', key: 'category', render: (e, f) => {
                return <><div style={{ "color": disabledColor }}><em>{e}</em></div><div style={{ "fontWeight": "500", "maxWidth": "100px", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}>{f.owner_name}</div></>
            }
        },
        {
            title: <small>Season</small>, dataIndex: 'effective_string', key: 'effective_string'
        },
        {
            title: <small>Folder</small>, dataIndex: 'folder', key: 'folder', render: (e, f) => {
                if(!props.is_company){
                    return (
                        <Space>
                            <ListFolder getLists={getCatalogues} results={results.rows} setResults={setResults} folder={e} list_id={f.list_id} />
                        </Space>
                    )
                }
            }
        },
        {
            title: <small>Trash, Archive, Fav</small>, width: "120px", dataIndex: 'folder', align: 'right', key: 'folder', render: (e, f) => {
                return (
                    <Space >
                        <ListTrash list={f} results={results} setResults={setResults} />
                        <ListArchive list={f} results={results} setResults={setResults} />
                        <ListFav list={f} results={results} setResults={setResults} getLists={getCatalogues} />
                    </Space>
                )
            }
        },
        {
            title: <small><nobr>Quick pick</nobr></small>, width: '60px', dataIndex: 'qp', key: 'qp', render: (e, f) => {
                return <ListQuickPick list={f} list_id={f.list_id} />
            }
        },
        { key: "space", width: "20px" },
    ];

    if (session.permissions.sales_rep) {
        columns.splice(columns.findIndex((col) => col.dataIndex === "category"), 0,
            {
                title: <small>Supplier</small>, width: "55px", dataIndex: "vendor", key: "vendor", render: (e, f) => {
                    if (f.vendor !== null) {
                        return (<>
                            {f.vendor.acct}
                        </>)
                    }
                }
            }
        )
    } else {
        columns.splice(columns.findIndex((col) => col.dataIndex === "category"), 0,
            {
                title: <small>Supplier</small>, width: "55px", dataIndex: "agency", key: "agency", render: (e, f) => {
                    if (f.agency !== null) {
                        return (<>
                            {f.agency.acct}
                        </>)
                    }
                }
            }
        )
    }

    if (selectMultiple) {
        columns.unshift({
            title: <small></small>, width: "0px", render: (e, f) => {
                return (<div style={{"marginLeft" : "-65px", "position" : "relative"}}><div style={{"marginLeft" : "0px", "position" : "absolute", "top" : "-10px"}}>Select <Checkbox checked={checkIfSelected(f)} onClick={(e) => toggleMultiSelect(f.list_id, e.target.checked)} /></div></div>)
            }
        },)
    }

    const displayGroup = () => {
        return (
            <>
                <Radio.Group size="small" value={showDetails.toString()} onChange={(e) => {
                    Cookies.set("lists_show_list", showDetails);
                    setShowDetails(!showDetails);
                }} buttonStyle="solid">
                    <Radio.Button value="true">
                        <Tooltip title={"Detailed View"}>
                            <small><PicLeftOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                    <Radio.Button value="false">
                        <Tooltip title={"Brief View"}>
                            <small><MenuOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                </Radio.Group>
            </>
        )
    }

    const SearchWithin = () => {
        const [value, setValue] = useState(searchFilters.t)
        return (
            <Input.Search
                value={value}
                onChange={(e) => setValue(e.target.value)}
                size="small"
                onSearch={(e) => {
                    setCurrent(1);
                    let sf = { ...searchFilters, "t": e, "o": 0 }
                    getCatalogues(false, sf);
                    history.push({
                        pathname: path + objectToPath(sf)
                    })
                }}
                allowClear
                enterButton
                placeholder="Enter ISBN, list id or keyword within a list's name"
                style={{ "width": "400px" }} />
        )
    }

    const drawFilters = () => {
        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px" }}>
                <Form layout="vertical">
                    <Space wrap >
                        <Form.Item noStyle label={<small>Find a list</small>} name="customer_search">
                            <SearchWithin />
                        </Form.Item>
                        <Form.Item noStyle label={<small>Sort</small>}>
                            <Select
                                getPopupContainer={trigger => trigger.parentElement}
                                className='ant-select-with-custom-arrow'
                                size="small"
                                defaultValue={"date_updated"}
                                onChange={(e) => {
                                    let sf = { ...searchFilters, "v": e }
                                    getCatalogues(false, sf);
                                    history.push({
                                        pathname: path + objectToPath(sf)
                                    })
                                }}
                                style={{ width: 130 }}>
                                <Select.Option value="date_updated" ><small>Last updated</small></Select.Option>
                                <Select.Option value=""><small>Date posted</small></Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle label={<small>Create new</small>}>
                            <Button
                                type="primary"
                                size="small"><small>
                                    <Overlay
                                        width={900}
                                        maskClosable={false}
                                        component={
                                            <ListCreateEdit
                                                create={true}
                                                getCatalogues={getCatalogues}
                                                results={catalogueResults}
                                                setResults={setCatalogueResults}
                                                data={props.data}
                                            />
                                        }
                                    >
                                        Create a catalogue
                                    </Overlay>
                                </small>
                            </Button>
                        </Form.Item>
                    </Space>
                    <br clear="both" />
                </Form>
            </div>
        )
    }

    const drawEmptyMessage = () => {
        // Show a search error message instead if a term has been entered...
        if (searchFilters.t) {
            return (<>Your search didn’t return any results. Please verify the list ID or keyword and try again.</>)
        }
        return (<>You have not created any catalogues. <br /> Create one (above), or select View My Company's Catalogues from the Catalogues menu, to see ones created by others in your company.</>)
    }

    function chosenListsForMultiSelect() {
        let return_list = results.rows.filter((item) => {
            if (selectedRowKeys.some((row) => row === item.list_id)) {
                return item;
            }
        })
        return return_list;
    }

    return (
        <> {(initLoaded &&
            <Layout className="layout" style={{ "minHeight": "200px" }}>
                <LayoutHeader
                    title={<><div style={{ "float": "right", "marginTop": "-7px" }}>{displayGroup()}</div>My {props.is_company ? "Company's " : ""} Catalogues</>}
                    filters={
                        <div style={{ "margin": "0px -20px", "marginTop": "-15px" }}>
                            <span>
                                <BrowseFilters
                                    listLabel={listLabel}
                                    loading={loading}
                                    filters={filters}
                                    setFilters={setFilters}
                                    toolboxes={results.toolboxes}
                                    header={props.is_company ? "corplists" : "mylists"}
                                    path={path}
                                    setCurrent={setCurrent}
                                    searchFilters={searchFilters}
                                    setSearchFilters={setSearchFilters}
                                />
                                {/* Select Tools is multi select stuff, name change was requested by Kellynda in GitLab issue: web/issues#2625 */}
                            </span>
                            {drawFilters()}
                            <div style={{ "margin": "0px 20px", "paddingBottom": "10px" }}>
                                <Space>
                                <Button disabled={(selectedRowKeys.length === 0)} onClick={() => setSelectedRowKeys([])} size='small' danger type='primary'>Clear Selected</Button>
                                <Button size='small' type='primary'>
                                    <Overlay
                                        width={900}
                                        component={
                                            <ListMultiSelect
                                                catalogues={chosenListsForMultiSelect()}
                                                selectedRowKeys={selectedRowKeys}
                                            />
                                        }
                                    >Select Tools ({selectedRowKeys.length} selected)
                                    </Overlay>
                                </Button>
                                </Space>
                            </div>
                        </div>
                    }
                />
                <conditional.true value={results.row_count > 0}>
                    <Layout.Content style={{ "padding": "0px" }} >
                        <Paginate loading={loading} paginate={{ current: current, offset: (searchFilters).hasOwnProperty("o") ? searchFilters.o : 0, pagesize: (searchFilters).hasOwnProperty("l") ? searchFilters.l : 20 }} setPaginate={(e) => {
                            setCurrent(e.current);
                            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                            getCatalogues(false, sf);
                            history.push({ pathname: path + objectToPath(sf) });
                        }} count={results.row_count} />
                        <div className="bc bcg2" style={{ "borderTop": (showDetails) ? "1px solid" : "none" }}>
                            <conditional.true value={(!showDetails)}>
                                <Table
                                    rowKey="list_id"
                                    size="small"
                                    columns={columns}
                                    loading={loading}
                                    className={"fullTable"}
                                    dataSource={results.rows}
                                    pagination={false}
                                    expandable
                                    expandedRowKeys={expanded}
                                    expandIconColumnIndex={-1}
                                    onExpand={(e) => { return false; }}
                                    expandIcon={() => { return (<></>) }}
                                    expandedRowRender={((record) => drawChildTable(record))}
                                />
                            </conditional.true>
                            <conditional.true value={(showDetails)}>
                                <Spin spinning={loading}>
                                    <div style={{ "paddingLeft": "20px", "paddingRight": "20px" }}>
                                        <conditional.true value={(results.rows.length < 1)}>
                                            <Empty description="No results." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </conditional.true>
                                        {results.rows.map((item, index) => {
                                            return <div key={item.list_id}>
                                                <Renderer>
                                                    <ListItem
                                                        is_company={props.is_company}
                                                        getCatalogues={getCatalogues}
                                                        setResults={setResults}
                                                        results={results}
                                                        data={item}
                                                        presetSANLists={presetSANLists}
                                                        selectedRowKeys={selectedRowKeys}
                                                        toggleMultiSelect={toggleMultiSelect}
                                                    />
                                                </Renderer>
                                                {(results.rows.length !== index + 1 && <div className="borderBottom shim" style={{ "marginRight": "-20px", "marginLeft": "-20px", "borderBottomWidth": "6px" }} />)}
                                            </div>
                                        })}
                                    </div>
                                </Spin>
                            </conditional.true>
                        </div>
                        <Paginate loading={loading} paginate={{ current: current, offset: (searchFilters).hasOwnProperty("o") ? searchFilters.o : 0, pagesize: (searchFilters).hasOwnProperty("l") ? searchFilters.l : 20 }} setPaginate={(e) => {
                            setCurrent(e.current);
                            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                            getCatalogues(false, sf);
                            history.push({ pathname: path + objectToPath(sf) });
                        }} count={results.row_count} />
                    </Layout.Content>
                </conditional.true>
                <conditional.true value={results.row_count <= 0}>
                    <Layout.Content>
                        <Empty
                            style={{ "fontSize": "18px", "fontWeight": "bold" }}
                            description={drawEmptyMessage()}
                            image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Layout.Content>
                </conditional.true>
            </Layout>)}
        </>
    )
}