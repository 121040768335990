import { InfoCircleOutlined, ShareAltOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Layout, Modal, Radio, Select, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import { nameSort } from '../../utils/Utils';
import IndividualAccount from '../content/IndividualAccount';

export default function MyAccountsPage() {

    const history = useHistory(); 
    const [results, setResults] = useState([]);
    const [initResults, setInitResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initLoaded, setInitLoaded] = useState(false);
    const [chosenView, setChosenView] = useState("all");

    const fetchAll = (_term) => {
        setLoading(true)
        let _sel = "all"
        let _obj = {
            my_accounts: (_sel === "my_accounts" || _sel === "all") ? true : false,
            approved: (_sel === "approved" || _sel === "all") ? true : false,
            pending: (_sel === "pending" || _sel === "all") ? true : false,
            declined: (_sel === "declined" || _sel === "all") ? true : false,
        }
        if (_term) {
            _obj.filter = _term;
        }
        if(_sel === "all"){
            _obj.potential = true;
        }else{
            _obj.potential = false;
        }
        apiCall("rep_control/getAll", _obj, (_status, _result) => {
            if (_status) {
                setInitResults(_result);
                organizeResults(_result, "all");
            }
        })
        setLoading(false)
        
    }
    
    function organizeResults(results_object, account_display){
        setLoading(true);
        setChosenView(account_display);
        let approved_stores = [];
        let basic_connected_stores = [];
        let declined_stores = [];
        let pending_stores = [];
        let remaining_stores = [];
        // "All"
        if(account_display === "all"){
            approved_stores = results_object.filter((row) => row.has_basic_link === true && row.basic_link_status === "approved" && row.share_relationship_info.status === "approved");
            basic_connected_stores = results_object.filter((row) => row.has_basic_link === true && row.basic_link_status === "approved" && (row.share_relationship_info.status !== "approved" || row.basic_link_status === "rerequested"))
            remaining_stores = results_object.filter((row) => row.share_relationship_info.status !== "approved")
            setResults(approved_stores.concat(basic_connected_stores, remaining_stores))
        }else if(account_display === "my_accounts"){
            // "My Accounts"
            approved_stores = results_object.filter((row) => row.share_relationship_info.status === "approved" || row.basic_link_status === "approved");
            approved_stores.concat(results_object.filter((row) => row.has_basic_link === true))
            setResults(approved_stores);
        }else{
            // "My Accounts Not Sharing Data"
            if(account_display === "not_sharing"){
                approved_stores = results_object.filter((row) => row.has_basic_link === true && row.basic_link_status === "declined")
            } 
            // "Sharing Data Only"
            if(account_display === "approved"){
                approved_stores = results_object.filter((row) => row.share_relationship_info.status === "approved");
            }
            // "Share Declined"
            if(account_display === "declined"){
                declined_stores = results_object.filter((row) => row.share_relationship_info.status === "declined");
            }
            // "Share requested"
            if(account_display === "pending" || account_display === "requested"){
                pending_stores = results_object.filter((row) => row.share_relationship_info.status === "requested" || row.share_relationship_info.status === "pending" || row.share_relationship_info.status === "rerequested" || row.share_relationship_info.status === "approved_inactive");
            }
            setResults(approved_stores.concat(declined_stores, pending_stores))
        }
        setInitLoaded(true);
        setLoading(false);
    }

//      This is the object breakdown per store
//      category:  "Bookstore"
//      city: "Kelowna"
//      contact_id: "PlzHTPKhH6g"
//      email: "orders@mosaicbooks.ca"
//      has_basic_link: true
//      name: "Mosaic Books"
//      province:  "BC"
//      san:  "1677004"
//      share_relationship_info: 
//          ack_contact_name: "George Cairney"
//          declined_reason: ""
//          last_change: 1701816599
//          relationship_id: "SdMW8sxpuBg"
//          status: "approved"
//      speciality: "General"

    useEffect(fetchAll, []);

    // Keeping the old one incase it's needed/wanted for the box. Easier than re-formatting
    // const getStarted = () => {
    //     Modal.info({ width: "650px", icon: <></>, title: "Getting started", content: <div style={{ "height": "calc(100vh - 300px)", "overflow": "auto", "paddingRight": "10px" }}>
    //             <Divider style={{ "margin": "15px 0px" }} />
    //             <p>Accounts listed on this page have selected your store as one they service. Below their name is the Pubstock company (or companies) that they represent.</p>
    //             <p>If the rep you want to share with is not listed on this page, you need to contact them. Many reps may be familiar with how to start this connection process, otherwise feel free to have them contact Bookmanager, and we'll be more than happy to walk them through it.</p>
    //             <Divider dashed style={{ "margin": "15px 0px" }} />
    //             <p>Accounts with a grey checkmark <nobr className="noInteract"><Checkbox checked disabled /></nobr> next to their name indicate that they are sharing lists, titles, and/or catalogues with you. These reps do not do not have access to see your inventory info.</p>
    //             <Divider dashed style={{ "margin": "15px 0px" }} />
    //             <p>Accounts with a green share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#78c042"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> are sharing lists and catalogues with you; and you are sharing your store data with them. If you have a good working relationship with a rep, sharing your store's inventory info (sales, on hand, order quantities) can help them do a better job of ensuring that you are not missing important opportunities. <strong className="c">A rep can only see information on the titles that they represent.</strong> And only reps that have the green share icon can see your data.</p>
    //             <Divider dashed style={{ "margin": "15px 0px" }} />
    //             <p>Accounts listed with an orange share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#e77e21"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> have requested to be connected with your store and your inventory data, but need approval from you first. These reps are presently only able to share lists, titles, and/or catalogues with you. If you want to decline the share request, simply click the red X <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#f12a46"><strong><CloseOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> next to their name, and you can then click to "hide"  them from this list.</p>
    //             <Divider dashed style={{ "margin": "15px 0px" }} />
    //             <p>Accounts listed with a purple share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#8f3fe4"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> indicate that this request has been sent more than once. These reps are presently only able to share lists, titles, and/or catalogues with you. If you want to decline the share request, simply click the red X <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#f12a46"><strong><CloseOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> next to their name, and you can then click to "hide"  them from this list.</p>
    //             <Divider dashed style={{ "margin": "15px 0px" }} />
    //             <p>Please contact <a href="mailto:mail@bookmanager.com">mail@bookmanager.com</a> | 250.763.4415 if you have any questions about sharing with your reps, and the data that is visible.</p>
    //         </div>
    //     })
    // }

    const getStarted = () => {
        Modal.info({ width: "650px", icon: <></>, title: "Getting started", content: <div style={{ "height": "calc(100vh - 300px)", "overflow": "auto", "paddingRight": "10px" }}>
                <Divider style={{ "margin": "15px 0px" }} />
                <p> Accounts listed on this page are stores that you can work with, stores you currently work with, and even stores you once worked with. As well, if you are working with a store, you are able to request to see their data.  </p>
                <p> If the store you want to work with is not listed on this page, you may need to search using the search bar, or try changing the filters to narrow down your results.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a gray add person icon <nobr className="noInteract"><Tag className="tag-btn" color="gray"><strong><UsergroupAddOutlined /></strong></Tag></nobr> means you are not connected to them, and need to send a request to connect. </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a green add person icon <nobr className="noInteract"><Tag className="tag-btn" color="#78c042"><strong><UsergroupAddOutlined /></strong></Tag></nobr> are ones that have agreed to work with you, but are not yet sharing their data with you.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a gray share icon <nobr className="noInteract"><Tag className="tag-btn" color="gray"><strong><ShareAltOutlined /></strong></Tag></nobr> means you have not yet requested to see their data. You cannot send a request to see their data until you have successfully connected with them </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a green share icon <nobr className="noInteract"><Tag className="tag-btn" color="#78c042"><strong><ShareAltOutlined /></strong></Tag></nobr> are sharing their store data with you, and you are able to share lists and catalogues with them.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts listed with a purple share icon <nobr className="noInteract"><Tag className="tag-btn" color="#8f3fe4"><strong><ShareAltOutlined /></strong></Tag></nobr> indicate that this request has been sent once or more and you are waiting on a response from the store</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Please contact <a href="mailto:mail@bookmanager.com">mail@bookmanager.com</a> | 250.763.4415 if you have any questions about sharing with your reps, and the data that is visible.</p>
            </div>
        })
    }

    const updateRow = (_item) => {
        let _update = [...results];
        let _index = _update.findIndex(itm => itm.contact_id === _item.contact_id);
        _update[_index] = _item;
        setResults(_update);
    }

    const headerValue = () => {
        return (<>
            My Accounts <Button size='small' type="text" onClick={() => getStarted()}><small><InfoCircleOutlined /> Getting started</small></Button>
        </>)
    }


    function filterResults(results_object, chosen_filter){
        setLoading(true);
        switch(chosen_filter){
            case "province":
                results_object.sort((a, b) => {
                    const provinceA = a.province.toUpperCase();
                    const provinceB = b.province.toUpperCase();
                    return nameSort(provinceA, provinceB);
                })
                break;
            case "city":
                results_object.sort((a, b) => {
                    const cityA = a.city.toUpperCase();
                    const cityB = b.city.toUpperCase();
                    return nameSort(cityA, cityB);
                })
                break;
            //default is equal to the "name" value, as it's the defaultValue of the Select
            default:
                results_object.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    return nameSort(nameA, nameB);
                })
        }
        organizeResults(results_object, chosenView)
    }

    return (
        <>
            {(!initLoaded || loading) && <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{"paddingTop": "150px", "textAlign": "center" }} >
                    <Spin 
                        size="large"
                        tip="Retrieving data for all accounts... please wait" 
                        style={{ "minHeight": "200px" }}
                        spinning={!initLoaded}></Spin>
                </Layout.Content>
            </Layout> }
            {(initLoaded && !loading)&& <Layout style={{ "backgroundColor": "#fff" }}>
                <LayoutHeader
                    title={headerValue()}
                    filters={
                        <Form size="small" layout="vertical">
                            <Space>
                                <Form.Item initialValue={(history.location.state) ? history.location.state : ""} name="keyword_search">
                                    <Input.Search onSearch={(e) => fetchAll(e)} allowClear enterButton placeholder="Search for a store" style={{ "width": "350px" }} />
                                </Form.Item>
                                <Form.Item>
                                    <Radio.Group onChange={(e) => organizeResults(initResults, e.target.value)} defaultValue={"all"} buttonStyle="solid">
                                        <Radio.Button value="all"><small>All</small></Radio.Button>
                                        <Radio.Button value="my_accounts"><small>All My Accounts</small></Radio.Button>
                                        <Radio.Button value="approved"><small>Sharing Data Only</small></Radio.Button>
                                        <Radio.Button value="not_sharing"><small>My Accounts Not Sharing Data</small></Radio.Button>
                                        <Radio.Button value="pending"><small>Share requested</small></Radio.Button>
                                        <Radio.Button value="declined"><small>Share declined</small></Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {/* <Form.Item >
                                    <Button type="text" onClick={() => getStarted()}><small><InfoCircleOutlined /> Getting started</small></Button>
                                </Form.Item> */}
                                <Form.Item>
                                    <Select 
                                        style={{"width": "150px"}}
                                        defaultValue="name"
                                        onChange={(e) => filterResults(initResults, e)}
                                        options={[
                                            {value: "name", label: "Sort by name"},
                                            {value: "province", label: "Sort by Provinces"},
                                            {value: "city", label: "Sort by City"},
                                            // {value: "status", label: "Sort by Status"},
                                        ]}
                                    />
                                </Form.Item>
                            </Space>
                            <br clear="both" />
                        </Form>
                    }
                />
                <Layout.Content className="layoutHeader" style={{"padding": "0px" }} >
                    <div style={{ "padding": "0px 20px", "marginTop": "-6px", "marginBottom": "-6px" }}>
                        <div style={{"padding" : "20px"}}>
                            {results.map((item, index) => {
                                return <IndividualAccount updateRow={updateRow} index={index} data={item} />
                            })}
                        </div>
                    </div>
                </Layout.Content>
            </Layout>
            }
        </>
    )
}